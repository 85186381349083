<template>
<Slide noOverlay right :crossIcon="true">
  <ul class="list-reset flex flex-col justify-start font-sm items-start font-medium normal-case text-left">
    <li class="my-4" v-for="node in mainNodes">
      <a class="my-6 text-white" :href="node.url">
        {{node.title}}
      </a>
    </li>

    <li class="account dropdown mt-4 ">
      <a class="flex text-white py-4 border-b border-t" href="#">Account </a>
      <ul class="list-reset pt-4 text-base text-left">
        <li><a class="text-white py-2 px-4 block whitespace-no-wrap" href="">My Orders</a></li>
        <li><a class="text-white py-2 px-4 block whitespace-no-wrap" href="">My Addresses</a></li>
        <!-- <li><a class="text-white py-2 px-4 block whitespace-no-wrap" href="">My Cards</a></li> -->
        <li><a class="text-white py-2 px-4 block whitespace-no-wrap" href="">Logout</a></li>
      </ul>
    </li>
  </ul>
</Slide>
</template>

<script>
import {
  Slide
} from 'vue-burger-menu' // import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
  props: {
    mainNodes: Array,
  },
  components: {
    Slide // Register your component
  }
}
</script>
