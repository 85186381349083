<template>
<div :class="{'mt-12': products.length }">
  <div class="flex flex-wrap container mx-auto" :class="{loading: loading}">

    <!-- <aside class="w-full sm:w-1/6 filters relative">
      <div class=" mx-auto absolute left-0 right-0" style="z-index:-1;" v-if="loading">
        <div class="w-full placeholder relative mb-6 fles flex-col items-start">
          <div class="h-4 bg-gray-200 mb-4 w-10/12 mx-4"></div>
          <div class="h-4 bg-gray-200 my-4 w-7/12 mx-4"></div>
          <div class="h-4 bg-gray-200 my-4 w-8/12 mx-4"></div>
          <div class="h-4 bg-gray-200 my-4 w-11/12 mx-4"></div>
          <div class="h-4 bg-gray-200 my-4 w-10/12 mx-4"></div>
        </div>
      </div>
      <ul class="pb-2 flex filters-enabled flex-wrap " v-if="enabledFilters.length">
        <li v-for="filter in enabledFilters" class="inline">
          <span v-if="typeof filter[1] == 'object'">
            <a v-for="item in filter[1]" @click="$refs['filter'+filter[0]+item][0].click()" class="bg-black text-white px-4 py-2 inline-block mr-1 mb-1">{{ item }}</a>
          </span>
          <span v-else>
            <a @click="$refs['filter'+filter[0]+filter[1]][0].click()" class="bg-black text-white px-4 py-2 inline-block mr-1 mb-1">{{ filter[1] }}</a>
          </span>
        </li>
      </ul>
      <div class="filter-all bg-white">
        <h3 class="font-bold sm:invisible" @click="$event.target.nextSibling.classList.toggle('open')">Filters</h3>
        <div class="filter-outer">
          <div class="py-2 border-b filter-wrapper" v-for="productFilter in productFilters">
            <h3 class="py-1 mb-0" @click.prevent="$event.target.parentElement.classList.toggle('open')">
              {{ productFilter.filterTitle }}
              <svg aria-hidden="true" focusable="false" role="presentation" class="w-4 h-4 icon icon--wide icon-chevron-down" viewBox="0 0 28 16">
                <path d="M1.57 1.59l12.76 12.77L27.1 1.59" stroke-width="2" stroke="#000" fill="none" fill-rule="evenodd"></path>
              </svg>
            </h3>
            <ul class="filter-group" v-if="productFilter.filterType == 'singleSelection'">
              <li class="py-2" v-for="c in productFilter.categories">
                <a href="#" class="filter-single" @click.prevent="toggleFilter($event, productFilter.categoryGroup, c.slug, false)" :ref="'filter'+productFilter.categoryGroup + c.slug">{{ c.title }}</a>
                <ul class="pt-2" v-if="c.subcategories">
                  <li class="py-2 pl-2" v-for="s in c.subcategories">
                    <a href="#" @click.prevent="toggleFilter($event, productFilter.categoryGroup, s.slug, false)" :ref="'filter'+productFilter.categoryGroup + s.slug">{{ s.title }}</a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul class="filter-group" v-else>
              <li class="py-2" v-for="c in productFilter.categories">
                <a href="#" class="filters-single" @click.prevent="toggleFilter($event, productFilter.categoryGroup, c.slug, true)" :ref="'filter'+productFilter.categoryGroup + c.slug">{{ c.title }}</a>
                <ul class="pt-2" v-if="c.subcategories">
                  <li class="py-2 pl-2" v-for="s in c.subcategories">
                    <a href="#" @click.prevent="toggleFilter($event, productFilter.categoryGroup, s.slug, true)" :ref="'filter'+productFilter.categoryGroup + s.slug">{{ s.title }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </aside> -->
    <div class="w-full sm:w-full">
      <div class="flex flex-wrap relative overflow-hidden container mx-auto" v-if="loading">
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder relative mb-6 flex flex-col">
          <div class=" h-48 bg-gray-200 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder relative mb-6">
          <div class=" h-48 bg-gray-200 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder relative mb-6">
          <div class=" h-48 bg-gray-200 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder relative mb-6">
          <div class=" h-48 bg-gray-200 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder relative mb-6">
          <div class=" h-48 bg-gray-200 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder relative mb-6">
          <div class=" h-48 bg-gray-200 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder relative mb-6">
          <div class=" h-48 bg-gray-200 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-2/3 mx-4">

          </div>
        </div>
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 placeholder relative mb-6">
          <div class=" h-48 bg-gray-200 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-10/12 mx-4">

          </div>
          <div class="h-4 bg-gray-200 my-4 w-2/3 mx-4">

          </div>
        </div>
      </div>
      <div v-if="products.length" class="flex flex-wrap container mx-auto">
        <div class="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4 mb-4" v-for="product in products">
          <product :product="product" @add-to-cart="addToCart" />
        </div>
      </div>
      <div v-else class="">
          <div class="flex justify-center">
              <div class="mb-12 px-8 py-12 bg-gray-200 justify-center">
                  {{ noProductsMessage}}
              </div>
          </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';

export default {
  name: 'product-listing',
  props: {
    noProductsMessage: String,
  },
  data() {
    return {
      csrfName: window.csrfTokenName,
      csrfToken: window.csrfTokenValue,
      productFilters: [],
      enabledFilters: [],
      products: [],
      loading: false
    }
  },
  mounted() {
    var _this = this;

    axios.get('/api/productFilters', {
        [this.csrfName]: this.csrfToken
      })
      .then(function(response) {
        _this.productFilters = response.data.data[0];
      })
      .catch(function(error) {
        console.log(error);
      });

    this.getProducts();
  },
  methods: {
    toggleFilter(event, group, value, multiple = false) {

      let exists = false;
      let _this = this;
      let groupExists = false;

      this.enabledFilters.forEach(function(el, i) {
        if (el[0] == group) {
          // group exists
          groupExists = i;
          if (multiple) {
            let index = el[1].indexOf(value);
            if (index > -1) {
              // value exists
              _this.enabledFilters[i][1].splice(index, 1);
              if (el[1].length == 0) {
                _this.enabledFilters.splice(i, 1);
              }
              exists = true;
            }
          } else {
            if (el[1] == value) {
              _this.enabledFilters.splice(i, 1);
              exists = true;
            }
          }
        }
      });

      if (exists) {
        if (event) {
          event.target.classList.remove('active');
        }
        return false;
      }

      // Since we did not return anything yet that means this value does not exist in filters
      if (multiple) {
        if (groupExists !== false) {
          this.enabledFilters[groupExists][1].push(value);
        } else {
          this.enabledFilters.push([group, [value]]);
        }
        if (event) {
          event.target.classList.add('active');
        }
      } else {
        if (groupExists !== false) {
          this.enabledFilters.splice(groupExists, 1);
          this.enabledFilters.push([group, value]);
        } else {
          this.enabledFilters.push([group, value]);
        }
        if (event) {
          $(event.target).closest('.filter-group').find('.active').removeClass('active');
          event.target.classList.add('active');
        }
      }

      return true;
    },
    isFilterEnabled(group, value, multiple = false) {
      this.enabledFilters.forEach(function(el, i) {
        if (multiple) {
          if (el[0] == group && el[1].indexOf(value) > -1) {
            return true;
          }
        } else {
          if (el[0] == group && el[1] == value) {
            return true
          }
        }
      });

      return false;
    },
    addToCart(id, qty) {
      this.$emit('add-to-cart', id, qty);
    },
    getProducts() {
      let _this = this;

      let query = {
        [this.csrfName]: this.csrfToken
      };

      this.enabledFilters.forEach(function(el) {
        query[el[0]] = el[1];
      });

      this.loading = true;
      axios.get('/api/products', {
          params: query
        })
        .then(function(response) {
          _this.products = response.data.data;
        })
        .catch(function(error) {
          console.log(error);
        })
        .then(function() {
          _this.loading = false;
        });
    }
  },
  watch: {
    enabledFilters() {
      console.log(this.enabledFilters);
      this.getProducts();
    }
  }
}
</script>

<style lang="scss" scoped>
.filters-single {
    .active {}
}
.filter-outer {
    @media screen and (max-width: 767px) {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease;

        &.open {
            max-height: 2000px;
        }
    }
}

.filter-group {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1), opacity 0.5s ease;

    .filter-wrapper.open & {
        opacity: 1;
        max-height: 1000px;
    }
}

.filter-wrapper {
    h3 {
        cursor: pointer;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        svg {
            pointer-events: none;
            transition: transform 0.2s ease;
            path {
                stroke: currentColor;
            }
        }
    }
    &.open {
        h3 {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}
</style>
