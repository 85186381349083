<template>
<div>
  <div class="w-full flex justify-between" id="menu-content">
    <div class="point text-center bg-gray-100 md:my-0" style="width:49%" :class="{active: isActive(index)}" v-for="(tab, index) in tabs" :key="tab.$attrs.title" v-on:click="(ev) => showTab(index)">
      <h5 class="py-1 md:pb-0 px-4 sm:px-6 pt-5 font-bold">{{ tab.$attrs.title }}</h5>
    </div>
  </div>
  <div>
    <slot></slot>
  </div>
</div>
</template>

<style>
.active h5 {}
</style>

<script>
import Tab from './Tab';
import EventBus from '../EventBus';

export default {
  components: {
    Tab,
  },
  data() {
    return {
      tabs: [],
      current: 0,

    }
  },
  mounted() {
    console.log(this.$children);
    this.tabs = this.$children.filter(child => child.$options._componentTag === 'tab');
    let hash = window.location.hash.replace(/#/g, '').replace(/-/g, ' ');
    this.$nextTick(() => EventBus.$emit('craft:tab-change'));

    let fromHash = false;
    if (hash) {
      this.tabs.forEach((tab, index) => {
        if (tab.$attrs.title === hash) {
          tab.show = true;
          this.current = index;
          fromHash = true
        }
      })
    }

    if (!fromHash)
      this.tabs[0].show = true;

  },
  methods: {
    showTab(tabIndex) {
      this.tabs.forEach((tab, index) => {
        if (index === tabIndex) {
          tab.show = true;
        } else {
          tab.show = false;
        }
      })
      this.current = tabIndex;
      window.location.hash = `#${this.tabs[tabIndex].$attrs.title.replace(/ /g, '-')}`
      this.$nextTick(() => EventBus.$emit('craft:tab-change'));
    },
    isActive(index) {
      return index === this.current
    }
  }
}
</script>
