<template>
<Slide noOverlay right :crossIcon="true">
  <ul class="list-reset flex flex-col justify-start font-sm items-start font-medium normal-case text-left">
    <li class="my-4" v-for="node in mainNodes">
      <a class="my-6 text-white" :href="node.url">
        {{ node.title }}
      </a>
    </li>

    <li class="my-4">
      <a class="my-6 text-white" href="http://syn221.syd2.hostyourservices.net/~legionfi/shop">
        Shop
      </a>
    </li>


    <li class="my-4">
      <a class="my-6 text-white" href="http://syn221.syd2.hostyourservices.net/~legionfi/contact">
        Contact
      </a>
    </li>


  </ul>
</Slide>
</template>

<script>
import {
  Slide
} from 'vue-burger-menu' // import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
  props: {
    mainNodes: Array,
  },
  components: {
    Slide // Register your component
  }
}
</script>
